









































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
const UserStore = namespace('UserStore')
const StatististicsStore = namespace('StatististicsStore')
import { RoomModel } from './models/RoomModel'
import { v4 as uuidv4 } from 'uuid'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'

import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import StatisticsCharts from '@/modules/components/StatisticsCharts.vue'
import NoBillingPlanWarning from './components/NoBillingPlanWarning.vue'
import Room from './components/Room.vue'
import TopBanner from '@/components/banners/TopBanner.vue'
import RoomForm from './components/RoomForm.vue'
import AddressForm from '@/modules/components/AddressForm.vue'
import IntegrationForm from './components/IntegrationForm.vue'
import ConditionForm from './components/ConditionForm.vue'
import Modal from '@/components/Modal.vue'
import BaseAddEntityBlock from '@/components/base/BaseAddEntityBlock.vue'
import { UserModel } from '@/core/models/UserModel'
import breakpoints from '@/plugins/breakpoints'

@Component({
  name: 'MyRooms',
  components: {
    BaseAddEntityBlock,
    Content,
    BaseTitle,
    BaseButton,
    BaseIcon,
    Room,
    RoomForm,
    AddressForm,
    IntegrationForm,
    StatisticsCharts,
    ConditionForm,
    Modal,
    NoBillingPlanWarning,
    TippyComponent,
    TopBanner
  },
  metaInfo: {
    title: 'Список помещений',
  },
})
export default class MyRoomsPage extends Vue {
  @Ref() readonly roomForm!: RoomForm

  updated = false

  createPlaceSuccess = false
  createPlaceSuccessMessage: string | null = ''

  openForm = false
  roomFormKey = uuidv4()
  isAddressFormVisible = false
  isIntegrationFormVisible = false
  isConditionFormVisible = false
  conditionForEdit = null
  conditionForEditIndex = null

  breakpoints = breakpoints

  @UserStore.Getter
  public currentUser!: UserModel

  @StatististicsStore.Getter
  public statististicsList!: any

  @RoomsStore.Getter
  public roomList!: RoomModel[]

  @RoomsStore.Action
  private getRooms!: () => Promise<RoomModel[]>

  @StatististicsStore.Action
  private getStatististics!: () => Promise<any>

  @StatististicsStore.Action
  private getBannerStatististics!: (id: any) => Promise<void>

  get isMobile(): boolean {
    return breakpoints.width <= 768
  }

  @Watch('currentUser')
  onCurrentUserChanged() {
    this.getBannerStatististics(this.currentUser.requisites.id)
  }

  get addAvailable(): boolean {
    return (
      !!this.currentUser?.billing_plan?.current ||
      !!this.currentUser?.billing_plan?.next
    )
  }

  get activeBillingPlan(): boolean {
    return !!this.currentUser?.billing_plan?.current
  }

  created(): void {
    this.openForm = false
    this.getRooms()
    this.getStatististics()
    this.createPlaceSuccess = false
  }

  placeCreated(message: string | null): void {
    this.openForm = false
    this.getRooms()
    this.getStatististics()
    this.createPlaceSuccess = true
    this.createPlaceSuccessMessage = message
  }

  addRoom(): void {
    this.roomFormKey = uuidv4()
    this.openForm = true
    this.isAddressFormVisible = false
    this.isIntegrationFormVisible = false
    this.isConditionFormVisible = false
    this.conditionForEditIndex = null
  }

  backToForm(): void {
    this.openForm = true
    this.isAddressFormVisible = false
    this.isIntegrationFormVisible = false
    this.isConditionFormVisible = false
    this.conditionForEditIndex = null
  }

  async editRoom(id: number): Promise<void> {
    this.roomFormKey = uuidv4()
    if (this.roomForm) {
      this.roomForm.resetModel()
      await this.roomForm.fetchModel(id)
      this.openForm = true
    }
  }

  showAddressForm(): void {
    this.isAddressFormVisible = true
    this.openForm = false
    this.isIntegrationFormVisible = false
  }

  showIntegrationForm(): void {
    this.isAddressFormVisible = false
    this.openForm = false
    this.isIntegrationFormVisible = true
  }

  showConditionForm(): void {
    this.conditionForEdit = null
    this.conditionForEditIndex = null
    this.isConditionFormVisible = true
    this.openForm = false
  }

  editCondition(payload: any): void {
    this.conditionForEdit = payload.condition
    this.conditionForEditIndex = payload.index
    this.isConditionFormVisible = true
    this.openForm = false
  }
}
