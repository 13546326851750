





















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Overlay from '@/components/Overlay.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { namespace } from 'vuex-class'
import { Condition, Model } from '@/store/modules/room.module'
import breakpoints from '@/plugins/breakpoints'

const StepOne = () => import('./RoomForm/StepOne.vue')
const StepTwo = () => import('./RoomForm/StepTwo.vue')
const StepThree = () => import('./RoomForm/StepThree.vue')
const StepFour = () => import('./RoomForm/StepFour.vue')
const StepFive = () => import('./RoomForm/StepFive.vue')

const StepsInfo = () => import('./RoomForm/parts/StepsInfo.vue')

const RoomStore = namespace('RoomStore')

@Component({
  name: 'RoomForm',
  components: {
    Overlay,
    BaseButton,
    BaseIcon,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepsInfo,
  },
})
export default class RoomForm extends Vue {
  @Prop({ required: true })
  updateFlag!: boolean

  @Prop({ default: true })
  show!: boolean

  backendErrorMessage = ''
  backendErrorMessageStep = 1
  saveDisabled = false
  breakpoints = breakpoints

  images: File[] = []
  activeButton = 1
  more = false
  days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
  buttons = [
    { id: 1, title: 'Описание', visible: true, disabled: false },
    { id: 2, title: 'Опции', visible: true, disabled: true },
    { id: 3, title: 'Расписание', visible: true, disabled: true },
    { id: 4, title: 'Фото', visible: true, disabled: true },
    { id: 5, title: 'Цены', visible: true, disabled: true },
  ]

  @RoomStore.State('model')
  model!: Model

  @RoomStore.State('conditions')
  conditions!: Condition[]

  @RoomStore.Mutation('setModel')
  setModel!: (model: Model) => void

  @RoomStore.Action('getModel')
  getModel!: (id: number) => Promise<void>

  @RoomStore.Mutation('resetModel')
  resetModel!: () => void

  @RoomStore.Action('createPlace')
  createPlace!: () => Promise<any>

  get isMobile(): boolean {
    return breakpoints.width < 768
  }

  get currentCount(): number {
    const arr = this.buttons.filter((button) => button.visible)
    return arr.length
  }

  get currentComponent(): string {
    switch (this.activeButton) {
      case 3:
        return 'StepTwo'
      case 2:
        return 'StepThree'
      case 4:
        return 'StepFour'
      case 5:
        return 'StepFive'
      default:
        return 'StepOne'
    }
  }

  get isShowBackendErrorMessage(): boolean {
    return (
      !!this.backendErrorMessage &&
      this.backendErrorMessageStep === this.activeButton
    )
  }

  checkValidity(): boolean {
    return (this.$refs[this.currentComponent] as any)?.checkValidity?.() ?? true
  }

  close(): void {
    this.resetModel()
    this.activeButton = 1
    this.backendErrorMessage = ''
    this.$emit('close')
  }

  fetchModel(id: number): Promise<void> {
    return this.getModel(id)
  }

  resetStep(): void {
    this.activeButton = 1
    ;(this.$refs[this.currentComponent] as any)?.resetValidity?.()
  }

  deleteCondition(index: number): void {
    this.conditions.splice(index, 1)
    this.checkValidity()
  }

  update<T>(data: T): void {
    this.setModel({
      ...this.model,
      [this.currentComponent]: data,
    })
  }

  async next(): Promise<any> {
    if (this.checkValidity() && this.activeButton === 5) {
      this.saveDisabled = true
      let result = await this.createPlace()
      this.backendErrorMessage = ''
      if (result.status === 'success') {
        this.$emit('place-created', result.message)
        this.resetModel()
        this.activeButton = 1
      } else {
        this.backendErrorMessage = result.message
        this.backendErrorMessageStep = this.activeButton
      }
      this.saveDisabled = false
    }
    if (
      this.checkValidity() &&
      this.activeButton <= this.currentCount &&
      this.activeButton < 5
    ) {
      const nextstep: any = this.buttons.find(
        (button) => button.id === this.activeButton + 1
      )
      nextstep.disabled = false
      this.activeButton += 1
    }
  }

  changeImages(images: File[]): void {
    this.images = images
  }

  getDays(weekdays: any): any {
    const newdays: any = []
    weekdays.forEach((day: any) => {
      for (let i = 0; i < 7; i++) {
        if (i === day - 1) {
          newdays.push(this.days[i])
        }
      }
    })
    return newdays.join(', ')
  }
}
