























































import { Component, Prop, Vue } from 'vue-property-decorator'
import Overlay from '@/components/Overlay.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import AddConditionForm from '@/modules/MyRoomsPage/components/AddConditionForm.vue'
import { namespace } from 'vuex-class'
import { Condition } from '@/store/modules/room.module'
const RoomStore = namespace('RoomStore')

@Component({
  name: 'ConditionForm',
  components: {
    Overlay,
    BaseIcon,
    BaseButton,
    AddConditionForm,
  },
})
export default class ConditionForm extends Vue {
  @Prop({ required: false })
  conditionForEdit!: any
  @Prop({ required: false })
  conditionForEditIndex!: any

  model: Condition = {
    id: undefined,
    weekdays: [],
    from: null,
    to: null,
    isVisitors: false,
    visitors: null,
    price: null,
    fullday: false,
  }

  @RoomStore.Mutation('setConditions')
  setConditions!: (conditions: Condition[]) => void

  @RoomStore.Mutation('setEditCondition')
  setEditCondition!: (args: any) => void

  @RoomStore.State('conditions')
  conditions!: Condition[]

  error = ''

  public submit(): void {
    this.error = ''
    if ((this.$refs.form as any).checkValidity()) {
      if (this.conditionForEditIndex !== null) {
        this.setEditCondition({
          condition: this.model,
          index: this.conditionForEditIndex,
        })
        this.$emit('close')
      } else {
        const conditions = [...this.conditions, this.model]
        this.setConditions(conditions)
        this.$emit('close')
      }
    }
  }
}
